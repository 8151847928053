import { createEnv } from "@t3-oss/env-nextjs";
import { z } from "zod";

// console.log(`muly:ENV`, {
//   env: process.env,
// });

export const env = createEnv({
  /**
   * Specify your server-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars.
   */
  server: {
    DATABASE_URL: z.string().url(),
    NODE_ENV: z.enum(["development", "test", "production"]),
    SUPABASE_SECRET_KEY: z.string(),
    SUPABASE_TOKEN: z.string(),
    ADMIN_SECRET: z.string(),
    UI_URL: z.string().url(),
    SENDGRID_API_KEY: z.string(),
    RESEND_API_KEY: z.string(),
    SLACK_NOTIFICATIONS_URL: z.string().url(),
    TRIGGER_API_URL: z.string().url(),
    TRIGGER_SECRET_KEY: z.string(),
    HUBSPOT_CLIENT_ID: z.string(),
    HUBSPOT_CLIENT_SECRET: z.string(),
    HUBSPOT_APPLICATION_ID: z.coerce.number(),
    HUBSPOT_DEVELOPER_API_KEY: z.string(),
    HUBSPOT_WEBHOOK_URL: z.string().url(),
    HUBSPOT_WEBHOOK_SECRET: z.string(),
    QUICKBOOKS_CLIENT_ID: z.string(),
    QUICKBOOKS_CLIENT_SECRET: z.string(),
    QUICKBOOKS_WEBHOOK_SECRET: z.string(),
    SALESFORCE_CLIENT_ID: z.string(),
    SALESFORCE_CLIENT_SECRET: z.string(),
    PIPEDRIVE_CLIENT_ID: z.string(),
    PIPEDRIVE_CLIENT_SECRET: z.string(),

    ANTHROPIC_API_KEY: z.string(),
    OPENAI_APIKEY: z.string(),
    GCP_CREDENTIALS_BASE64: z.string(),
    LANGFUSE_SECRET_KEY: z.string(),
    LANGFUSE_PUBLIC_KEY: z.string(),
    UNKEY_API_ID: z.string(),
    UNKEY_ROOT_KEY: z.string(),
    LIBPOSTAL_REST_URL: z.string(),
    GOOGLE_MAPS_API_KEY: z.string(),
    SMARTY_AUTH_ID: z.string(),
    SMARTY_AUTH_TOKEN: z.string(),
  },
  /**
   * Specify your client-side environment variables schema here. This way you can ensure the app
   * isn't built with invalid env vars. To expose them to the client, prefix them with
   * `NEXT_PUBLIC_`.
   */
  client: {
    NEXT_PUBLIC_SUPABASE_ANON_KEY: z.string(),
    NEXT_PUBLIC_SUPABASE_URL: z.string().url(),
    NEXT_PUBLIC_ENVIRONMENT: z.string(),
    NEXT_PUBLIC_TRIGGER_PUBLIC_API_KEY: z.string(),
  },

  /**
   * You can't destruct `process.env` as a regular object in the Next.js edge runtimes (e.g.
   * middlewares) or client-side so we need to destruct manually.
   */
  runtimeEnv: {
    DATABASE_URL: process.env.DATABASE_URL,
    NODE_ENV: process.env.NODE_ENV,
    SUPABASE_SECRET_KEY: process.env.SUPABASE_SECRET_KEY,
    SUPABASE_TOKEN: process.env.SUPABASE_TOKEN,
    NEXT_PUBLIC_SUPABASE_ANON_KEY: process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY,
    NEXT_PUBLIC_SUPABASE_URL: process.env.NEXT_PUBLIC_SUPABASE_URL,
    ADMIN_SECRET: process.env.ADMIN_SECRET,
    UI_URL: process.env.UI_URL,
    SENDGRID_API_KEY: process.env.SENDGRID_API_KEY,
    RESEND_API_KEY: process.env.RESEND_API_KEY,
    NEXT_PUBLIC_ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT,
    SLACK_NOTIFICATIONS_URL: process.env.SLACK_NOTIFICATIONS_URL,
    TRIGGER_API_URL: process.env.TRIGGER_API_URL,
    TRIGGER_SECRET_KEY: process.env.TRIGGER_SECRET_KEY,
    NEXT_PUBLIC_TRIGGER_PUBLIC_API_KEY:
      process.env.NEXT_PUBLIC_TRIGGER_PUBLIC_API_KEY,
    HUBSPOT_CLIENT_ID: process.env.HUBSPOT_CLIENT_ID,
    HUBSPOT_CLIENT_SECRET: process.env.HUBSPOT_CLIENT_SECRET,
    HUBSPOT_APPLICATION_ID: process.env.HUBSPOT_APPLICATION_ID,
    HUBSPOT_DEVELOPER_API_KEY: process.env.HUBSPOT_DEVELOPER_API_KEY,
    HUBSPOT_WEBHOOK_URL: process.env.HUBSPOT_WEBHOOK_URL,
    HUBSPOT_WEBHOOK_SECRET: process.env.HUBSPOT_WEBHOOK_SECRET,
    QUICKBOOKS_CLIENT_ID: process.env.QUICKBOOKS_CLIENT_ID,
    QUICKBOOKS_CLIENT_SECRET: process.env.QUICKBOOKS_CLIENT_SECRET,
    QUICKBOOKS_WEBHOOK_SECRET: process.env.QUICKBOOKS_WEBHOOK_SECRET,
    SALESFORCE_CLIENT_ID: process.env.SALESFORCE_CLIENT_ID,
    SALESFORCE_CLIENT_SECRET: process.env.SALESFORCE_CLIENT_SECRET,
    PIPEDRIVE_CLIENT_ID: process.env.PIPEDRIVE_CLIENT_ID,
    PIPEDRIVE_CLIENT_SECRET: process.env.PIPEDRIVE_CLIENT_SECRET,

    LANGFUSE_SECRET_KEY: process.env.LANGFUSE_SECRET_KEY,
    LANGFUSE_PUBLIC_KEY: process.env.LANGFUSE_PUBLIC_KEY,
    ANTHROPIC_API_KEY: process.env.ANTHROPIC_API_KEY,
    OPENAI_APIKEY: process.env.OPENAI_APIKEY,
    GCP_CREDENTIALS_BASE64: process.env.GCP_CREDENTIALS_BASE64,
    UNKEY_API_ID: process.env.UNKEY_API_ID,
    UNKEY_ROOT_KEY: process.env.UNKEY_ROOT_KEY,
    LIBPOSTAL_REST_URL: process.env.LIBPOSTAL_REST_URL,
    GOOGLE_MAPS_API_KEY: process.env.GOOGLE_MAPS_API_KEY,
    SMARTY_AUTH_ID: process.env.SMARTY_AUTH_ID,
    SMARTY_AUTH_TOKEN: process.env.SMARTY_AUTH_TOKEN,
  },
  /**
   * Run `build` or `dev` with `SKIP_ENV_VALIDATION` to skip env validation.
   * This is especially useful for Docker builds.
   */
  skipValidation: !!process.env.SKIP_ENV_VALIDATION,
});
