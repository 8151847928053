import * as Sentry from "@sentry/react";
import type { FallbackRender } from "@sentry/react/build/types/errorboundary";
import type { Session } from "@supabase/auth-helpers-nextjs";
import { createPagesBrowserClient } from "@supabase/auth-helpers-nextjs";
import {
  SessionContextProvider,
  useSupabaseClient,
} from "@supabase/auth-helpers-react";
import { TriggerProvider } from "@trigger.dev/react";
import { TaskProvider } from "~/components/common/task-status/task-status.context";
import type { NextComponentType, NextPageContext } from "next";
import type { AppProps } from "next/app";
import { Plus_Jakarta_Sans } from "next/font/google";
import { useRouter } from "next/router";
import type { ReactNode } from "react";
import { useEffect, useState } from "react";
import { ConfigProvider } from "~/components/common/config/config-context";
import { ErrorBoundaryComponent } from "~/components/common/ui/ErrorBoundaryComponent";
import { Layout } from "~/components/layout/Layout";
import { Toaster as ToasterSonner } from "~/components/ui/sonner";
import { TooltipProvider } from "~/components/ui/tooltip";
import { env } from "~/env.mjs";
import "~/styles/globals.css";
import { api } from "~/utils/api";

const plusJakartaSans = Plus_Jakarta_Sans({
  subsets: ["latin"],
  variable: "--font-plusJakartaSans ",
});

interface PageProperties {
  disableLayout?: boolean;
  disableNeedUser?: boolean;
  disableNeedOrganizationData?: boolean;
  expandSideMenu?: boolean;
}

type MyAppProps = AppProps<{ initialSession: Session }> & {
  Component: NextComponentType<NextPageContext, any, any> & PageProperties;
};

function MyApp({ Component, pageProps }: MyAppProps) {
  // Create a new supabase browser client on every first render.
  const [supabaseClient] = useState(() => createPagesBrowserClient());

  const pageProperties = {
    disableLayout: Component.disableLayout,
    disableNeedUser: Component.disableNeedUser,
    disableNeedOrganizationData: Component.disableNeedOrganizationData,
    expandSideMenu: Component.expandSideMenu,
  };

  return (
    <TriggerProvider publicApiKey={env.NEXT_PUBLIC_TRIGGER_PUBLIC_API_KEY}>
      <SessionContextProvider
        supabaseClient={supabaseClient}
        initialSession={pageProps.initialSession}
      >
        <TaskProvider>
          <InternalApp pageProperties={pageProperties}>
            <Component {...pageProps} />
          </InternalApp>
        </TaskProvider>
      </SessionContextProvider>
    </TriggerProvider>
  );
}

function InternalApp({
  children,
  pageProperties,
}: {
  children: ReactNode;
  pageProperties: PageProperties;
}) {
  const router = useRouter();
  const supabase = useSupabaseClient();
  const [supabaseSession, setSupabaseSession] = useState<
    Session | null | undefined
  >(undefined);
  const [authEventsReceived, setAuthEventsReceived] = useState(false);

  const pageNeedUser = pageProperties.disableNeedUser !== true;
  const pageNeedOrganizationData =
    pageProperties.disableNeedOrganizationData !== true;

  useEffect(() => {
    const authListener = supabase.auth.onAuthStateChange(
      (event, sessionClient) => {
        if (supabaseSession?.user?.id !== sessionClient?.user?.id) {
          console.log(`muly:_app:onAuthStateChange EVENT=${event}`, {
            event,
            sessionClient,
            id: supabaseSession?.user?.id,
            idNew: sessionClient?.user?.id,
          });

          setSupabaseSession(sessionClient);
        }
        setAuthEventsReceived(true);
      },
    );

    // Clean up the listener when the component unmounts
    return () => {
      authListener.data.subscription.unsubscribe();
    };
    // be careful what we add Here
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSupabaseSession, supabaseSession?.user?.id]);

  const sentryErrorFallback: FallbackRender = ({
    error,
    componentStack,
    // eslint-disable-next-line @typescript-eslint/unbound-method
    resetError,
  }) => {
    console.error(`muly:MyApp:Sentry.ErrorBoundary`, {
      error,
      componentStack,
      resetError,
    });

    return (
      <ErrorBoundaryComponent
        onReload={() => {
          resetError();
          router.reload();
        }}
        error={error as Error}
      />
    );
  };

  return (
    <main className={plusJakartaSans.className}>
      <Sentry.ErrorBoundary fallback={sentryErrorFallback}>
        <ConfigProvider
          supabase={supabase}
          supabaseSession={supabaseSession}
          authEventsReceived={authEventsReceived}
          pageNeedUser={pageNeedUser}
          pageNeedOrganizationData={pageNeedOrganizationData}
          expandSideMenu={pageProperties.expandSideMenu ?? false}
        >
          <TooltipProvider>
            {!!pageProperties.disableLayout ? (
              children
            ) : (
              <Layout>{children}</Layout>
            )}
          </TooltipProvider>
          <ToasterSonner />
        </ConfigProvider>
      </Sentry.ErrorBoundary>
    </main>
  );
}

export default api.withTRPC(MyApp);
