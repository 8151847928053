import { useEffect } from "react";
import type { FieldValues, UseFormReturn } from "react-hook-form";
import { toast } from "sonner";

export const useShowErrors = <TFieldValues extends FieldValues>(
  form: UseFormReturn<TFieldValues>,
) => {
  useEffect(() => {
    const errors = form.formState.errors;
    if (Object.keys(errors).length > 0) {
      Object.entries(errors).forEach(([fieldName, error]) => {
        toast.error(`${error?.message}`, {
          duration: 5000,
          style: {
            color: "white",
            border: "1px solid darkred",
          },
          className: "!bg-destructive",
        });
      });
    }
  }, [form.formState.errors]);
};
