import type { ReactNode } from "react";
import { useState } from "react";
import { useConfigContext } from "~/components/common/config/config-context";
import type { BreadCrumbItem } from "~/components/layout/breadcrumb-context";
import { BreadcrumbContext } from "~/components/layout/breadcrumb-context";
import { Footer } from "~/components/layout/Footer";
import { Header } from "~/components/layout/Header";
import { CustomSidebarMenu } from "~/components/layout/Sidebar/SidebarMenu";
import { SidebarProvider } from "../ui/sidebar";

export const Layout = ({ children }: { children: ReactNode }) => {
  const { userProfile, organizationId } = useConfigContext();
  const [collapsedSidebar, setCollapsedSidebar] = useState(
    window.innerWidth < 768 ? true : false,
  );

  const handleMenuItemClick = () => {
    if (window.innerWidth < 768) {
      setCollapsedSidebar(true);
    }
  };

  const [breadcrumb, setBreadcrumb] = useState<BreadCrumbItem[]>([]);

  if (userProfile) {
    return (
      <BreadcrumbContext.Provider value={setBreadcrumb}>
        <div className="flex h-screen flex-row">
          <SidebarProvider>
            <CustomSidebarMenu
              organizationId={organizationId}
              collapsed={collapsedSidebar}
              userProfile={userProfile}
              onMenuItemClick={handleMenuItemClick}
            />
            <div className="relative flex w-full flex-col overflow-x-hidden">
              <Header
                onSidebarToggleClick={() =>
                  setCollapsedSidebar(!collapsedSidebar)
                }
                userProfile={userProfile}
                breadcrumb={breadcrumb}
              />
              <main className="m-4 mt-11">{children}</main>
              <div className="flex-1">
                <div className="flex h-full items-end">
                  <Footer />
                </div>
              </div>
            </div>
          </SidebarProvider>
        </div>
      </BreadcrumbContext.Provider>
    );
  }

  return children;
};
