import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export const NumOrZero = (num: number | null | undefined): number =>
  Number.isFinite(num ?? 0) ? (num ?? 0) : 0;

export function truncateString(str: string, maxlength: number) {
  return str.length > maxlength ? str.slice(0, maxlength - 1) + "." : str;
}

export const capitalizeFirstLetter = (input: string): string => {
  if (!input) return input;
  return input.charAt(0).toUpperCase() + input.slice(1);
};

// tremor utils below
export function cx(...args: ClassValue[]) {
  return twMerge(clsx(...args));
}
export const focusInput = [
  "focus:ring-2",
  "focus:ring-blue-200 focus:dark:ring-blue-700/30",
  "focus:border-blue-500 focus:dark:border-blue-700",
];

export const focusRing = [
  "outline outline-offset-2 outline-0 focus-visible:outline-2",
  "outline-blue-500 dark:outline-blue-500",
];

export const hasErrorInput = [
  "ring-2",
  "border-red-500 dark:border-red-700",
  "ring-red-200 dark:ring-red-700/30",
];
