import { ShortMessage } from "~/components/common/ui/ShortMessage";
import { Button } from "~/components/common/ui/button";
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import { useRouter } from "next/router";
import { FeedbackDialog } from "~/components/common/ui/FeedbackDialog";

interface Props {
  onReload: () => void;
  error: Error;
}

export const ErrorBoundaryComponent = ({ onReload, error }: Props) => {
  const router = useRouter();
  const supabase = useSupabaseClient();

  const logout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) {
      console.error("Error logging out:", error.message);
    } else {
      // await router.replace("/login");
      router.reload();
    }
  };

  const logoutOption = error.message.includes(" (C:6");
  return (
    <ShortMessage
      title="Error"
      text={error.message}
      icon="error"
      className="mx-auto flex min-h-screen max-w-3xl flex-col items-center justify-center"
    >
      <div className="flex flex-row gap-4">
        {logoutOption && (
          <Button
            variant="destructive"
            onClick={logout}
            className="mx-auto w-fit px-14"
          >
            Logout
          </Button>
        )}
        <div className="ml-auto flex gap-4">
          <FeedbackDialog
            trigger={
              <Button variant="primary" className="w-fit px-14">
                Let Us Know
              </Button>
            }
          />

          <Button variant="primary" onClick={onReload} className="w-fit px-14">
            Reload
          </Button>
        </div>
      </div>
    </ShortMessage>
  );
};
