import { BookOpen, PanelLeft } from "lucide-react";
import Link from "next/link";
import { Fragment, useState } from "react";
import type { BreadCrumbItem } from "~/components/layout/breadcrumb-context";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "~/components/ui/breadcrumb";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "~/components/ui/tooltip";
import type { UserProfile } from "~/server/db-types";
import { SidebarTrigger } from "../ui/sidebar";
import { cn } from "~/lib/utils";
import { isDev, isStaging } from "~/utils/nextjs-utils";
import { SearchSelect } from "../searchSelect/SearchSelect";
import { useRouter } from "next/router";

interface HeaderProps {
  onSidebarToggleClick: () => void;
  userProfile: UserProfile;
  breadcrumb: BreadCrumbItem[];
}

export const Header = ({
  userProfile,
  onSidebarToggleClick,
  breadcrumb,
}: HeaderProps) => {
  const isAdmin = userProfile.role === "ADMIN" && !isDev() && !isStaging();
  const router = useRouter();
  const [selectValue, setSelectValue] = useState(userProfile.organizationId);
  const handleOnChange = async (organizationId: string) => {
    if (!organizationId) return;
    setSelectValue(organizationId);

    await router.replace(`/${organizationId}`);
    router.reload();
  };
  return (
    <div
      className={cn(
        "absolute top-0 flex w-full flex-row items-center gap-2 bg-white p-0 pl-4 pt-1",
        {
          "bg-[url('/warning-stripes.svg')] bg-center bg-repeat": isAdmin,
        },
      )}
    >
      <SidebarTrigger onClick={onSidebarToggleClick} className="size-5" />

      <div>
        <Breadcrumb>
          <BreadcrumbList>
            {breadcrumb.map((breadcrumbItem, index) => (
              <Fragment key={index}>
                <BreadcrumbItem>
                  <BreadcrumbLink href={breadcrumbItem.href}>
                    {breadcrumbItem.label}
                  </BreadcrumbLink>
                </BreadcrumbItem>
                {breadcrumb.length - 1 !== index && <BreadcrumbSeparator />}
              </Fragment>
            ))}
          </BreadcrumbList>
        </Breadcrumb>
      </div>
      {userProfile.integrationsWorkProfile !== "NORMAL" && (
        <div className="-mt-1 rounded-full bg-critical px-6 py-1.5 text-sm text-white">
          {userProfile.integrationsWorkProfile}
        </div>
      )}
      <div className="ml-auto flex gap-2">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Link
                target="_blank"
                href="https://docs.cube9.co/"
                className="flex items-center"
              >
                <BookOpen size={14} />
              </Link>
            </TooltipTrigger>
            <TooltipContent>
              <p>Documentation</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
        {userProfile.organizationMember.length > 1 ? (
          <SearchSelect
            value={selectValue}
            handleOnChange={handleOnChange}
            selectData={userProfile.organizationMember}
            placeholder="Select company..."
            labelClassName="border-none"
            alignContent="end"
          />
        ) : (
          <span className="pr-4">
            {userProfile?.organizationMember[0]?.name}
          </span>
        )}
      </div>
    </div>
  );
};
