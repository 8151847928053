import Link from "next/link";
import { useRouter } from "next/router";
import { FeedbackDialog } from "~/components/common/ui/FeedbackDialog";
import MenuItemLink from "~/components/layout/Sidebar/MenuItemLink";
import { Avatar, AvatarFallback, AvatarImage } from "~/components/ui/avatar";
import { cn } from "~/lib/utils";
import type { UserProfile } from "~/server/db-types";
import { api } from "~/utils/api";
import { getAvatarInitials } from "~/utils/avatar-initials";
import {
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarMenu,
  Sidebar,
  SidebarHeader,
  SidebarFooter,
  SidebarMenuButton,
} from "../../ui/sidebar";
import SubMenuItemLink from "./SidebarSubMenu";
import useGetSidebarData from "./sideBarData";
import { ChevronRight, MessageCircle } from "lucide-react";

interface SidebarMenuProps {
  collapsed: boolean;
  organizationId: string;
  userProfile: UserProfile;
  onMenuItemClick: () => void;
}

export const CustomSidebarMenu = ({
  organizationId,
  userProfile,
  collapsed,
  onMenuItemClick,
}: SidebarMenuProps) => {
  const router = useRouter();
  const isOrgAdmin = userProfile.organizationRole === "MANAGER";
  const isAdmin = userProfile.role === "ADMIN";
  const { menuItems, SubMenuData } = useGetSidebarData(isAdmin, isOrgAdmin);
  const { data: queryCounts } = api.misc.getNotificationQueryCache.useQuery(
    {
      organizationId,
    },
    {
      placeholderData: (prev) => prev || {},
      refetchOnWindowFocus: false,
    },
  );

  return (
    <Sidebar className="bg-brand text-white">
      <SidebarHeader className="bg-brand">
        <Link className="hover:cursor-pointer" href="/" tabIndex={-1}>
          <img
            src="/logo/LogoSidebar.png"
            className="mx-auto my-5 cursor-pointer"
            width={collapsed ? 90 : 110}
            alt="logo"
          />
        </Link>
      </SidebarHeader>
      <SidebarContent className="bg-brand text-white">
        <SidebarGroup>
          <SidebarGroupContent>
            <SidebarMenu>
              {menuItems.map(({ href, label, Icon }, index) => {
                return (
                  <MenuItemLink
                    key={index}
                    id={index}
                    href={href}
                    onClick={onMenuItemClick}
                    label={label}
                    Icon={Icon}
                    className={cn({
                      "!w-18 ml-2": collapsed,
                    })}
                  />
                );
              })}
              {SubMenuData.map(
                (
                  {
                    dataLabAlert,
                    label,
                    DataLabAlertIcon,
                    subMenuItems,
                    Icon,
                    canRender,
                  },
                  index,
                ) => {
                  return (
                    canRender && (
                      <SubMenuItemLink
                        key={index}
                        Icon={Icon}
                        subMenuItems={subMenuItems}
                        label={label}
                        DataLabAlertIcon={DataLabAlertIcon}
                        dataLabAlert={dataLabAlert}
                      />
                    )
                  );
                },
              )}
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
      <SidebarFooter className="bg-brand text-white">
        <FeedbackDialog
          trigger={
            <SidebarMenuButton
              asChild
              className="h-10 cursor-pointer text-base"
            >
              <div className="flex items-center">
                <MessageCircle size={24} />
                <span>Feedback</span>
              </div>
            </SidebarMenuButton>
          }
        />
        <Link href={`/${organizationId}/account`}>
          <SidebarMenuButton
            asChild
            className="h-14 text-sm"
            isActive={router.asPath === `/${router.query.orgId}/account`}
          >
            <div className="flex items-center">
              <Avatar
                className={cn("size-11", {
                  "mx-auto -ml-2": collapsed,
                })}
              >
                <AvatarImage src={userProfile.avatarImage ?? undefined} />
                <AvatarFallback className="text-sm text-black">
                  {getAvatarInitials(userProfile.name)}
                </AvatarFallback>
              </Avatar>
              {!collapsed && (
                <div className="flex w-3/4 gap-1">
                  <p className="truncate">
                    {userProfile.name || userProfile.email}
                  </p>
                  <span className="ml-auto">
                    <ChevronRight />
                  </span>
                </div>
              )}
            </div>
          </SidebarMenuButton>
        </Link>
      </SidebarFooter>
    </Sidebar>
  );
};
