import type { buttonVariants } from "~/components/ui/button";
import { Button as UIButton } from "~/components/ui/button";
import { Loader2 } from "lucide-react";
import * as React from "react";
import { forwardRef } from "react";
import type { VariantProps } from "class-variance-authority";
import Ripple from "./Ripple";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ loading, children, ...props }, ref) => {
    return (
      <UIButton disabled={loading} {...props}>
        {!!loading && <Loader2 className="mr-2 size-4 animate-spin" />}
        {!loading || props.size !== "icon" ? children : null}
        <Ripple duration={2000} />
      </UIButton>
    );
  },
);

Button.displayName = "Button";
