import React, { useState, useLayoutEffect } from "react";
import { cn } from "~/lib/utils";

interface RippleProps {
  duration?: number;
}

const useDebouncedRippleCleanUp = (
  rippleCount: number,
  duration: number,
  cleanUpFunction: () => void,
) => {
  useLayoutEffect(() => {
    let bounce: NodeJS.Timeout | null = null;
    if (rippleCount > 0) {
      if (bounce) {
        clearTimeout(bounce);
      }

      bounce = setTimeout(() => {
        cleanUpFunction();
        if (bounce) {
          clearTimeout(bounce);
        }
      }, duration * 4);
    }

    return () => {
      if (bounce) {
        clearTimeout(bounce);
      }
    };
  }, [rippleCount, duration, cleanUpFunction]);
};

const Ripple: React.FC<RippleProps> = ({ duration = 850 }) => {
  const [rippleArray, setRippleArray] = useState<
    { x: number; y: number; size: number }[]
  >([]);

  useDebouncedRippleCleanUp(rippleArray.length, duration, () => {
    setRippleArray([]);
  });

  const addRipple = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const rippleContainer = event.currentTarget.getBoundingClientRect();
    const size = Math.max(rippleContainer.width, rippleContainer.height);
    const x = event.pageX - rippleContainer.x - size / 2;
    const y = event.pageY - rippleContainer.y - size / 2;
    const newRipple = { x, y, size };

    setRippleArray((prev) => [...prev, newRipple]);
  };

  return (
    <div className={"absolute inset-0 overflow-hidden"} onMouseDown={addRipple}>
      {rippleArray.length > 0 &&
        rippleArray.map((ripple, index) => (
          <span
            key={index}
            className={`absolute animate-ripple rounded-full bg-light3 opacity-75`}
            style={{
              top: ripple.y,
              left: ripple.x,
              width: ripple.size,
              height: ripple.size,
            }}
          />
        ))}
    </div>
  );
};

export default Ripple;
