import { Check, ChevronsUpDown, Plus, Search, X } from "lucide-react";
import { useEffect, useState } from "react";
import { Button } from "~/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "~/components/ui/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "~/components/ui/popover";
import { cn } from "~/lib/utils";

interface Props {
  value: string;
  handleOnChange: (value: string) => void;
  selectData: { id: string; name: string }[];
  placeholder: string;
  inputClassName?: string;
  labelClassName?: string;
  contentClassName?: string;
  itemClassName?: string;
  inputTypingControlOn?: boolean;
  allowAddSearchValue?: boolean;
  alignContent?: "start" | "center" | "end";
}

export const SearchSelect = ({
  value,
  handleOnChange,
  selectData,
  placeholder,
  inputClassName,
  labelClassName,
  contentClassName,
  itemClassName,
  inputTypingControlOn = true,
  allowAddSearchValue = false,
  alignContent = "center",
}: Props) => {
  const [iconCondition, setIconCondition] = useState<"search" | "cross">(
    "search",
  );
  const [searchValue, setSearchValue] = useState("");
  useEffect(() => {
    if (searchValue && searchValue.length > 0) {
      setIconCondition("cross");
    } else {
      setIconCondition("search");
    }
  }, [searchValue]);

  const sortedOptions = (selectData ?? []).sort((a, b) =>
    a.name.localeCompare(b.name),
  );

  const [open, setOpen] = useState(false);

  return (
    <div>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className={cn(
              "mr-2 flex justify-between break-all shadow-none",
              labelClassName,
            )}
          >
            <span className="w-full truncate text-left">
              {value
                ? sortedOptions.find((item) => item.id === value)?.name || value
                : "Choose..."}
            </span>
            <ChevronsUpDown className="size-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent
          align={alignContent}
          className={cn(
            "left-0 z-50 max-h-40 max-w-40 overflow-y-auto p-0",
            contentClassName,
          )}
        >
          <Command>
            <CommandInput
              className={inputClassName}
              placeholder={placeholder}
              value={searchValue}
              onValueChange={(value) => {
                const valueWithoutTrimStart = value.trimStart();
                if (inputTypingControlOn) {
                  if (
                    sortedOptions.find((item) =>
                      item.name.toLowerCase().includes(value.toLowerCase()),
                    )
                  ) {
                    setSearchValue(valueWithoutTrimStart);
                  } else {
                    setSearchValue(valueWithoutTrimStart.replace(/\s+$/, ""));
                  }
                } else {
                  setSearchValue(valueWithoutTrimStart);
                }
              }}
            >
              {sortedOptions.filter((item) =>
                item.name.toLowerCase().includes(searchValue.toLowerCase()),
              ).length === 0 &&
                allowAddSearchValue && (
                  <Plus
                    onClick={() => {
                      handleOnChange(searchValue);
                      setOpen(false);
                    }}
                    className="mx-2 size-4 shrink-0 cursor-pointer rounded-full opacity-50 transition-all hover:bg-black hover:text-white"
                  />
                )}
              {iconCondition === "search" && (
                <Search className="mx-2 size-4 shrink-0 opacity-50" />
              )}
              {iconCondition === "cross" && (
                <X
                  onClick={() => {
                    setSearchValue("");
                  }}
                  className="mx-2 size-4 shrink-0 cursor-pointer rounded-full opacity-50 transition-all hover:bg-black hover:text-white"
                />
              )}
            </CommandInput>
            <CommandList>
              <CommandEmpty>No items found.</CommandEmpty>
              <CommandGroup>
                {sortedOptions.map(({ id, name }) => (
                  <CommandItem
                    className={cn(
                      "flex cursor-pointer select-all justify-start break-all data-[disabled]:pointer-events-auto data-[disabled]:opacity-100",
                      itemClassName,
                    )}
                    key={id}
                    value={name}
                    onSelect={(currentValue) => {
                      setOpen(false);
                      const id = sortedOptions.find(
                        (item) =>
                          item.name.toLowerCase().trim() ===
                          currentValue.toLowerCase().trim(),
                      )?.id;
                      return handleOnChange(id || value || "");
                    }}
                  >
                    <Check
                      className={cn(
                        "mr-1 size-3 min-w-3",
                        value === name || value === id
                          ? "opacity-100"
                          : "opacity-0",
                      )}
                    />
                    <span>{name}</span>
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
};
