import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
} from "react";
import { toast } from "sonner";
import { useRealtimeRun } from "@trigger.dev/react-hooks";

export enum TaskStatus {
  Running = "running",
  Completed = "completed",
  Failed = "failed",
}

export interface Task {
  runId: string;
  taskName: string;
  authToken: string;
  status: TaskStatus;
  cube9ObjectId: string | undefined;
  message?: string;
}

type UpdateTaskStatus = (
  runId: string,
  status: TaskStatus.Completed | TaskStatus.Failed,
  message?: string,
) => void;

interface TaskContextType {
  tasks: Map<string, Task>;
  addTask: (
    taskName: string,
    runId: string,
    authToken: string,
    cube9ObjectId?: string,
  ) => void;
  updateTaskStatus: UpdateTaskStatus;
  tasksStatuses: Map<string, TaskStatus>;
  deleteTaskStatus: (id: string) => void;
}

export const TaskContext = createContext<TaskContextType | undefined>(
  undefined,
);

const SubscriptionManager: React.FC<{
  runId: string;
  accessToken: string;
  onRunComplete: UpdateTaskStatus;
}> = React.memo(({ runId, accessToken, onRunComplete }) => {
  const { run } = useRealtimeRun(runId, {
    accessToken,
  });

  useEffect(() => {
    if (!run) return;
    console.log(`muly:task-status.context: ${run.status}`, { run });

    if (run.status === "COMPLETED") {
      onRunComplete(runId, TaskStatus.Completed);
    } else if (run.status === "FAILED") {
      onRunComplete(runId, TaskStatus.Failed, run.error?.message);
    }
  }, [onRunComplete, run, runId]);

  return null;
});
SubscriptionManager.displayName = "SubscriptionManager";

export const TaskProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [tasks, setTasks] = useState<Map<string, Task>>(new Map());
  const [tasksStatuses, setTasksStatuses] = useState<Map<string, TaskStatus>>(
    new Map(),
  );

  const addTask = useCallback(
    (
      taskName: string,
      runId: string,
      authToken: string,
      cube9ObjectId?: string,
    ) => {
      setTasks((prev) => {
        const next = new Map(prev);
        next.set(runId, {
          taskName,
          runId,
          cube9ObjectId,
          authToken,
          status: TaskStatus.Running,
        });
        return next;
      });

      if (cube9ObjectId) {
        setTasksStatuses((prev) => {
          const next = new Map(prev);
          next.set(cube9ObjectId, TaskStatus.Running);
          return next;
        });
      }

      toast.loading(`${taskName} started`, { id: runId });
    },
    [],
  );

  const updateTaskStatus = useCallback<UpdateTaskStatus>(
    (runId, status, message) => {
      setTasks((prev) => {
        const next = new Map(prev);
        const task = next.get(runId);

        if (task) {
          if (task.status === TaskStatus.Running) {
            if (status === TaskStatus.Completed) {
              toast.success(`${task.taskName} completed!`, {
                id: runId,
                duration: 5000,
              });
            } else {
              toast.error(`${task.taskName} failed!`, {
                id: runId,
                duration: 5000,
              });
            }
          }

          next.set(runId, {
            ...task,
            status,
            message,
          });

          if (task.cube9ObjectId) {
            setTasksStatuses((prev) => {
              const next = new Map(prev);
              next.set(task.cube9ObjectId || "", status);
              return next;
            });

            if (
              status === TaskStatus.Completed ||
              status === TaskStatus.Failed
            ) {
              next.delete(runId);
            }
          }
        }
        return next;
      });
    },
    [],
  );

  const deleteTaskStatus = (id: string) => {
    setTasksStatuses((prev) => {
      const next = new Map(prev);
      next.delete(id);
      return next;
    });
  };

  return (
    <TaskContext.Provider
      value={{
        tasks,
        addTask,
        updateTaskStatus,
        tasksStatuses,
        deleteTaskStatus,
      }}
    >
      {Array.from(tasks.values()).map((task) => (
        <SubscriptionManager
          key={task.runId}
          runId={task.runId}
          accessToken={task.authToken}
          onRunComplete={updateTaskStatus}
        />
      ))}
      {children}
    </TaskContext.Provider>
  );
};

export const useTaskMonitor = () => {
  const context = useContext(TaskContext);
  if (!context) {
    throw new Error("useTaskMonitor must be used within a TaskProvider");
  }
  return context;
};
