import * as z from "zod";
import { NotificationCategory, ObjectName, SyncState } from "@prisma/client";
import { IntegrationName } from "~/server/integrations/credentials-types";

// noinspection TypeScriptValidateTypes
export const optionalUuidSchema = z.string().uuid().nullish();

export const IssueDetails = z.any();

export const InvoiceNotificationInfo = z.array(
  z.object({
    category: z.nativeEnum(NotificationCategory),
    message: z.string(),
  }),
);

export const FieldChangeAudit = z.object({
  fieldName: z.string(),
  newValue: z.any(),
  oldValue: z.any(),
});

export type FieldChangeAudit = z.infer<typeof FieldChangeAudit>;
export const FieldChangesAudit = z.array(FieldChangeAudit);

export const FieldDiff = z.object({
  fieldName: z.string(),
  integrationFieldName: z.string().optional(),

  cube9Value: z.any(),
  integrationValue: z.any(),
});

export type FieldDiff = z.infer<typeof FieldDiff>;
export const FieldsDiff = z.array(FieldDiff);

export const ChangeReportsChanges = z.record(z.any());

export const DealLineTier = z.object({
  upTo: z.number().nullish(),
  flatAmount: z.number().nullish(),
  unitAmount: z.number().nullish(),
});

export const DealLineTiers = z.array(DealLineTier);

export const ObjectIntegrationGapPossibleMatch = z.array(
  z.object({
    cube9objectId: z.string(),
    name: z.string(),
  }),
);

export const EnumMapSyncConfig = z.array(
  z.object({
    cube9: z.string().nullish(),
    id: z.string(),
    label: z.string().optional(),
  }),
);
export type EnumMapSyncConfig = z.infer<typeof EnumMapSyncConfig>;

export const FieldsConfigMappings = z.object({
  integrationFieldName: z.string().nullish(),
  priority: z.number().default(0),

  enumMap: EnumMapSyncConfig.nullish(),
  // TODO: add support for field permissions
});

export type FieldConfigMapping = z.infer<typeof FieldsConfigMappings>;
export const IntegrationData = z.record(z.any()).nullish();

export const Cube9SyncConfig = z.record(
  z.nativeEnum(ObjectName),
  z.record(z.string(), FieldsConfigMappings), // key is cube9FieldName
);

export type Cube9SyncConfig = z.infer<typeof Cube9SyncConfig>;

export const DealParsingConfig = z.object({
  usageBase: z.boolean().default(true),
  llmSpecialInstructions: z.string().nullish(),
});
