import Link from "next/link";
import { useRouter } from "next/router";
import {
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
} from "../../ui/sidebar";
import { ChevronRight } from "lucide-react";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../../ui/collapsible";
import type { SubMenuItemLinkProps } from "./sidebarTypes";
import { type LegacyRef, useRef } from "react";

const SubMenuItemLink = ({
  subMenuItems,
  label,
  Icon,
  DataLabAlertIcon,
  dataLabAlert = false,
}: SubMenuItemLinkProps) => {
  const router = useRouter();
  const iconRef = useRef<SVGSVGElement | null>(null);
  const linkTo = (href: string, externalLink: boolean) => {
    return !externalLink ? `/${router.query.orgId}${href}` : `${href}`;
  };

  return (
    <Collapsible asChild defaultOpen={false} className="group/collapsible">
      <SidebarMenuItem>
        <CollapsibleTrigger asChild>
          <SidebarMenuButton className="h-10 text-base">
            {Icon && <Icon />}
            <span>{label}</span>
            {dataLabAlert && DataLabAlertIcon && (
              <DataLabAlertIcon style={{ color: "red" }} />
            )}
            <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
          </SidebarMenuButton>
        </CollapsibleTrigger>
        <CollapsibleContent>
          <SidebarMenuSub>
            {subMenuItems?.map(
              (
                {
                  hasSubMenu = false,
                  onClick,
                  href,
                  label,
                  subMenuAlert = false,
                  secondSubMenuItems = [],
                  externalLink = false,
                },
                index,
              ) => {
                if (!hasSubMenu) {
                  return (
                    <SidebarMenuSubItem key={index}>
                      <SidebarMenuSubButton
                        className="h-8 text-base"
                        asChild
                        onClick={onClick}
                        isActive={
                          router.asPath === `/${router.query.orgId}${href}`
                        }
                      >
                        <Link
                          className="text-white"
                          target={!externalLink ? "" : "_blank"}
                          href={linkTo(href || "", externalLink)}
                        >
                          <span>{label}</span>
                          {subMenuAlert && DataLabAlertIcon && (
                            <DataLabAlertIcon style={{ color: "red" }} />
                          )}
                        </Link>
                      </SidebarMenuSubButton>
                    </SidebarMenuSubItem>
                  );
                } else {
                  return (
                    <Collapsible
                      key={index}
                      className="group/nested"
                      defaultOpen={false}
                    >
                      <SidebarMenuItem>
                        <CollapsibleTrigger asChild>
                          <SidebarMenuButton className="h-8 text-base">
                            <span>{label}</span>
                            <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/nested:rotate-90" />
                          </SidebarMenuButton>
                        </CollapsibleTrigger>
                        <CollapsibleContent>
                          <SidebarMenuSub>
                            {secondSubMenuItems?.map(
                              (
                                {
                                  href,
                                  label,
                                  externalLink = false,
                                  IconRight,
                                },
                                index,
                              ) => {
                                return (
                                  <SidebarMenuSubItem key={index}>
                                    <SidebarMenuSubButton
                                      className="h-8 text-sm"
                                      asChild
                                      isActive={
                                        router.asPath ===
                                        `/${router.query.orgId}${href}`
                                      }
                                    >
                                      <Link
                                        onMouseOver={() => {
                                          if (
                                            iconRef.current &&
                                            href ==
                                              "https://app.theneo.io/c7fccff2-6701-40eb-8ca3-7bcccf801727/cube9"
                                          ) {
                                            iconRef.current.style.color =
                                              "black";
                                          }
                                        }}
                                        onMouseOut={() => {
                                          if (
                                            iconRef.current &&
                                            href ==
                                              "https://app.theneo.io/c7fccff2-6701-40eb-8ca3-7bcccf801727/cube9"
                                          ) {
                                            iconRef.current.style.color =
                                              "white";
                                          }
                                        }}
                                        className="text-white"
                                        target={!externalLink ? "" : "_blank"}
                                        href={linkTo(href || "", externalLink)}
                                      >
                                        <span>{label}</span>
                                        {externalLink && IconRight && (
                                          <IconRight
                                            ref={iconRef}
                                            color="white"
                                            size={12}
                                          />
                                        )}
                                      </Link>
                                    </SidebarMenuSubButton>
                                  </SidebarMenuSubItem>
                                );
                              },
                            )}
                          </SidebarMenuSub>
                        </CollapsibleContent>
                      </SidebarMenuItem>
                    </Collapsible>
                  );
                }
              },
            )}
          </SidebarMenuSub>
        </CollapsibleContent>
      </SidebarMenuItem>
    </Collapsible>
  );
};

export default SubMenuItemLink;
