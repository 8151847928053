import { Search, X } from "lucide-react";
import { useQueryState } from "next-usequerystate";
import { useState } from "react";
import { Button } from "~/components/ui/button";
import { FormControl, FormItem, FormLabel } from "~/components/ui/form";
import { cn } from "~/lib/utils";
import { Input } from "../ui/input";

interface Options {
  key: string;
  label?: string;
  placeholder?: string;
  defaultValue?: string;
}

export const useQueryStateText = ({
  key,
  label,
  placeholder,
  defaultValue,
}: Options) => {
  const [value, setValue] = useQueryState(key);

  // Used to rerender input field
  const [keyCounter, setKeyCounter] = useState(0);

  const control = (
    <FormItem className="mt-0">
      <FormLabel>{label}</FormLabel>
      <FormControl>
        <div className="relative max-w-52">
          <div className="relative flex items-center">
            <Search className="absolute left-3 size-4 text-search-300" />
            <Input
              placeholder={placeholder}
              value={value || ""}
              onChange={(e) => setValue(e.target.value)}
              key={keyCounter}
              className="pl-10 pr-6 text-xs"
            />
            {value && (
              <div className="absolute right-2">
                <Button
                  type="button"
                  onClick={async () => {
                    await setValue("");
                    setKeyCounter((prevCounter) => prevCounter + 1);
                  }}
                  size="icon"
                  className={cn(
                    "size-fit cursor-pointer border-0 bg-search-300 p-px text-white hover:bg-foreground",
                  )}
                >
                  <X className="text-white" size={12} />
                </Button>
              </div>
            )}
          </div>
        </div>
      </FormControl>
    </FormItem>
  );

  return [value, control] as const;
};
