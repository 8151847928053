import Link from "next/link";
import { useRouter } from "next/router";
import type { ReactNode } from "react";
import { SidebarMenuButton, SidebarMenuItem } from "../../ui/sidebar";
import type { LucideIcon } from "lucide-react";

interface Props {
  id: number;
  href?: string;
  label: string | ReactNode;
  Icon?: LucideIcon;
  onClick?: () => void;
  className?: string;
  externalLink?: boolean;
}

const MenuItemLink = ({
  id,
  href,
  label,
  Icon,
  onClick,
  className,
  externalLink = false,
}: Props) => {
  const router = useRouter();

  // console.log("router", router.asPath);
  // console.log("href", `/${router.query.orgId}${href}`);

  const linkTo = (href: string) => {
    return !externalLink ? `/${router.query.orgId}${href}` : `${href}`;
  };
  return (
    <SidebarMenuItem key={id} className={className}>
      <SidebarMenuButton
        className="h-10 text-base"
        asChild
        onClick={onClick}
        isActive={
          href === "/"
            ? router.asPath === `/${router.query.orgId}`
            : router.asPath === `/${router.query.orgId}${href}`
        }
      >
        <Link target={!externalLink ? "" : "_blank"} href={linkTo(href || "")}>
          {Icon && <Icon />}
          <span>{label}</span>
        </Link>
      </SidebarMenuButton>
    </SidebarMenuItem>
  );
};

export default MenuItemLink;
